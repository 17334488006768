import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import { Scrollbars } from 'react-custom-scrollbars';
import { ChromePicker } from 'react-color';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import './App.css';
import messengers from './messengers.js';

// import Flag from '@material-ui/icons/Flag';
// import Settings from '@material-ui/icons/Settings';
// import Code from '@material-ui/icons/Code';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/grey';
import Widget from './widget/Widget';
import Snackbar from '@material-ui/core/Snackbar';

const APP_VERSION = 1; // UP TO UPDATE

function mask(text, id) {
    if (!text) {
        return null
    }

    if (id === 0) {
        return `https://www.facebook.com/${text}`;
    } else if (id === 1) {
        text = text.replace('+', '');
        return `https://api.whatsapp.com/send?phone=${text}`;
    } else if (id === 2) {
        text = text.replace('+', '');
        return `viber://pa?chatURI=${text}`;
    } else if (id === 3) {
        return `https://t.me/${text}`;
    } else if (id === 4) {
        return `skype:${text}`;
    } else if (id === 5) {
        return `https://vk.me/${text}`;
    } else if (id === 6) {
        return `mailto:${text}`;
    }

    return text
}

const theme = createMuiTheme({
    palette: {
        primary: { main: blue[500] },
        secondary: { main: green[600] },
    },
    typography: {
        useNextVariants: true
    }
});

const styles = theme => ({
    text: {
        display: 'inline',
    },
    doneButton: {
        marginTop: '32px',
        marginBottom: '64px',
    },
    input: {
        transform: 'translate(56px, -105px)',
        width: '40vw',
        minWidth: '200px',
        marginBottom: '-100px',
    },
    close: {
        // marginTop: '-50px'
    },
    form: {
        width: '25vw',
        minWidth: '225px',
        marginRight: theme.spacing.unit * 4,
        marginLeft: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 2,
    },
    color: {
        display: 'inline-block',
    },
});

class App extends Component {
    constructor(props) {

        /* is not a */ super(props);

        let prevState = JSON.parse(window.localStorage.getItem('state') || "{}");

        if (prevState.version === APP_VERSION) {
            this.state = prevState;
        } else {
            this.state = {
                version: APP_VERSION,
                color: "#499efc",
                position: 'right',
                size: 'huge',
                messengers: [], // Мессенджеры: null - не выбран;
                open: false,
                generatedCode: null,
                generatedCodeHTML: null,
                focusInput: null,
            }
        }

        window.__getStoreScreenshot__ = () => this.state;
    }

    componentDidMount() {
        window.onbeforeunload = () => {
            window.localStorage.setItem('state', JSON.stringify(this.state));
            window.localStorage.setItem("scrollPos", this.refs.scrollbars.getScrollTop());
        };

        this.refs.scrollbars.scrollTop(window.localStorage.getItem("scrollPos") || 0);
    }

    toggleMessenger(id) {
        let messengers = [...this.state.messengers];

        if (this.state.messengers[id] !== undefined && this.state.messengers[id] !== null) {
            messengers[id] = undefined;
        } else {
            messengers[id] = "";
        }

        return this.setState({ messengers, focusInput: id });
    }

    clearHref(id) {
        let messengers = [...this.state.messengers];

        messengers[id] = "";

        return this.setState({ messengers });
    }

    handleChangeMsg(id, value) {
        let messengers = [...this.state.messengers];

        messengers[id] = value;

        return this.setState({ messengers });
    }

    handleColor = (color) => {
        let el2 = document.getElementsByClassName('arcontactus-message-button')[0];
        let els3 = document.getElementsByClassName('icons-line')[0];

        el2.style.backgroundColor = color.hex;

        els3.childNodes.forEach(node => {
            node.style.color = color.hex;
        });

        this.setState({
            color: color.hex
        });

    }

    Messengers = () => {
        const classes = this.props.classes;

        return (
            messengers.map((messenger, id) => {
                if (this.state.messengers[id] !== undefined && this.state.messengers[id] !== null) {
                    return <div key={id} onClick={this.toggleMessenger.bind(this, id)}
                        className="messengers">
                        <div className="svg-main" style={{ color: messenger.color, borderColor: messenger.color }}>
                            <span dangerouslySetInnerHTML={{ __html: messenger.icon }}></span>
                        </div>

                        <TextField
                            id={"TextField_" + id}
                            inputRef={ref => this.state.focusInput === id ? ref ? ref.focus() : null : null}
                            onBlur={() => this.state.messengers[id] === '' ? this.setState({ messengers: this.state.messengers.map((i, ind) => id === ind ? null : i) }) : null}
                            onClick={e => e.stopPropagation()}
                            label={messenger.input}
                            helperText={messenger.helperText}
                            onChange={e => this.handleChangeMsg(id, e.currentTarget.value.slice(messenger.mask.length))}
                            value={messenger.mask + this.state.messengers[id]}
                            fullWidth
                            className={classes.input}
                            InputLabelProps={{
                                style: {
                                    'color': messenger.color
                                }
                            }}
                            InputProps={{
                                classes: {
                                    underline: 'custom-input'

                                },
                                style: {
                                    'color': messenger.color
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.toggleMessenger.bind(this, id)} aria-label="Удалить содержимое">
                                            <Close className={classes.close} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                    </div>
                } else {
                    return <div key={id} onClick={this.toggleMessenger.bind(this, id)} id={messenger.title} className={`svg-main`}>
                        <span dangerouslySetInnerHTML={{ __html: messenger.icon }}></span>
                        <Typography className="text-off">{messenger.input}</Typography>
                    </div>
                }
            })
        )
    }

    Thumb = () => {
        const thumbStyle = {
            borderRadius: '25px',
            backgroundColor: blue[500]
        };

        return (
            <div style={{ ...thumbStyle }} />
        );
    }

    createCode = () => {
        let size = this.state.size;
        let position = this.state.position;
        let color = this.state.color;
        let msg = this.state.messengers.map((item, index) => mask(item, index));

        let code = `<script>
    window.__WIDGET_DATA__={align:"${position}",buttonSize:"${size}",theme:"${color}",items:${JSON.stringify(msg)}};
    var w = window.document.createElement('script');
    w.src = 'http://w.chatbullet.com/cdn/widget.js';
    w.charset = 'UTF-8';
    window.document.head.appendChild(w);
</script>`;

        let html = `&lt;script&gt;
    window.__WIDGET_DATA__={align:"${position}",buttonSize:"${size}",theme:"${color}",items:${JSON.stringify(msg)}};
    var w = window.document.createElement('script');
    w.src = 'http://w.chatbullet.com/cdn/widget.js';
    w.charset = 'UTF-8';
    window.document.head.appendChild(w);
&lt;/script&gt;`;

        if (messengers.length) {
            return ({
                generatedCode: code,
                generatedCodeHTML: html,
            });
        } else {
            return ({
                generatedCode: "NULL",
                generatedCodeHTML: "NULL",
            });
        }
    }

    render() {
        const classes = this.props.classes;
        const code = this.createCode();

        return (
            <Scrollbars ref="scrollbars" autoHide renderThumbHorizontal={this.Thumb}
                renderThumbVertical={this.Thumb}
                style={{ width: '100%', height: '100vh' }} >
                <MuiThemeProvider theme={theme}>
                    <Typography style={{ marginTop: '4vh' }} align="center" variant="h5">Получите виджет за 3 простых шага</Typography>
                    <div className="box">

                        <div className="svg">
                            <p>1</p>
                            {/* <Flag /> */}
                        </div>
                        <Typography className={classes.text} variant="h6">Выберите один или несколько мессенджеров</Typography>

                        <div className="messengers-block">
                            <this.Messengers />
                        </div>
                    </div>
                    <div className="box">
                        <div className="svg" style={{ marginTop: '150px' }}>
                            <p>2</p>
                        </div>

                        <Typography className={classes.text} variant="h6">Настройте виджет</Typography>

                        <div className="grid">
                            <div>
                                <p style={{ marginBottom: theme.spacing.unit * 1, }}>Цвет:</p>
                                <div style={{ marginTop: theme.spacing.unit * 3, marginBottom: theme.spacing.unit * 3 }}>
                                    <ChromePicker color={this.state.color} onChange={this.handleColor} className={classes.color} />
                                </div>
                            </div>

                            <div className="radio">
                                <p>Размер:</p>
                                <Radio
                                    color="primary"
                                    checked={this.state.size === 'small'}
                                    onChange={e => this.setState({ size: e.target.value })}
                                    value="small"
                                    name="Маленький"
                                    aria-label="A"
                                />Маленький<br />
                                <Radio
                                    color="primary"
                                    checked={this.state.size === 'medium'}
                                    onChange={e => this.setState({ size: e.target.value })}
                                    value="medium"
                                    name="Большой"
                                    aria-label="B"
                                />Средний<br />
                                <Radio
                                    color="primary"
                                    checked={this.state.size === 'huge'}
                                    onChange={e => this.setState({ size: e.target.value })}
                                    value="huge"
                                    name="Большой"
                                    aria-label="B"
                                />Большой<br />
                            </div>

                            <div className="radio">
                                <p>Расположение:</p>
                                <Radio
                                    color="primary"
                                    checked={this.state.position === 'left'}
                                    onChange={e => this.setState({ position: e.target.value })}
                                    value="left"
                                    name="Слева"
                                    aria-label="A"
                                />Слева<br />
                                <Radio
                                    color="primary"
                                    checked={this.state.position === 'right'}
                                    onChange={e => this.setState({ position: e.target.value })}
                                    value="right"
                                    name="Справа"
                                    aria-label="B"
                                />Справа<br />
                            </div>
                        </div>

                    </div>

                    <div className="box">
                        <div className="svg">
                            <p>3</p>
                        </div>

                        <Typography className={classes.text} variant="h6">Добавьте код виджета на ваш сайт</Typography>

                        <Paper style={{ margin: '55px 0 35px 0', padding: '15px' }}>
                            <Tooltip title="Нажмите, чтобы скопировать код">
                                <code dangerouslySetInnerHTML={{ __html: code.generatedCodeHTML }} style={{ userSelect: 'text', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} onClick={() => {
                                    if (navigator.clipboard) {
                                        navigator.clipboard.writeText(code.generatedCode)
                                            .then(() => {
                                                this.setState({ open: 'Код успешно скопирован' });
                                            })
                                            .catch(err => {
                                                alert('Ошибка буфера обмена, невозможно скопировать код');
                                                console.log(err);
                                            });
                                    } else {
                                        this.setState({ open: 'Копирование невозможно, скопируйте код вручную' });
                                    }
                                }} />
                            </Tooltip>
                        </Paper>

                        <Fab onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(code.generatedCode)
                                    .then(() => {
                                        this.setState({ open: 'Код успешно скопирован' });
                                    })
                                    .catch(err => {
                                        alert('Ошибка буфера обмена, невозможно скопировать код');
                                        console.log(err);
                                    });
                            } else {
                                this.setState({ open: 'Копирование невозможно, скопируйте код вручную' });
                            }
                        }} variant="extended" className={classes.doneButton}>
                            <Done className={classes.extendedIcon} />
                            Скопировать код
                    </Fab>

                    </div>
                </MuiThemeProvider>

                <Widget ids={messengers.map((text, id) => {
                    if (this.state.messengers[id] !== undefined
                        && this.state.messengers[id] !== null) {
                        return this.state.messengers[id]
                    } else {
                        return null
                    }
                })} position={this.state.position} size={this.state.size} color={this.state.color} />

                <Snackbar
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={!!this.state.open}
                    onClose={() => this.setState({ open: false })}
                    message={<span id="message-id">{this.state.open}</span>}
                />

            </Scrollbars >
        );
    }
}

export default withStyles(styles)(App);
